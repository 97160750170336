import React, { useEffect, useState, useCallback, useRef } from 'react';
import moment from 'moment';
import { Table } from 'components';
import { FormDetail } from './form-detail';
import { Box, IconButton, Typography } from '@mui/material';
import Utils from 'commons/utils';
import ApiServices from 'services';
import {
  BsBoxArrowInUpRight,
  BsArrowDownCircle,
  BsPlusCircle,
  BsFilter,
  BsFillStickiesFill,
} from 'react-icons/bs';
import { Columns } from './columns';
import { ContainerHeader, ButtonAdd, ButtonImport, ButtonExport } from './styles';
import { RESPONSE_STATUSES } from 'commons/constants';
import { debounce } from 'lodash';

const CLIENT_TRASH_TEMPLATE = 'Client_Template';
const DEFAULT_FILTER = {
  pageNumber: 1,
  pageSize: 10,
};

function Clients() {
  const inputRef = useRef();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const [selected, setSelected] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filter, setFilter] = useState(DEFAULT_FILTER);

  const initData = (query) => {
    setLoading(true);
    return ApiServices.getPagingClient(query ? query : filter)
      .then((res) => {
        if (res?.data) {
          if (totalRecords !== res?.data?.totalRecords) setTotalRecords(res?.data?.totalRecords);
          setClients(res?.data?.data || []);
        }
      })
      .catch((err) => console.warn('get Clients:', err))
      .finally(() => setLoading(false));
  };

  const handleSearchColumn = (event) => {
    setFilter((prev) => {
      const searchArray = { ...prev.searchArray };
      const { value, name } = event.target;
      if (!value) {
        delete searchArray[name];
        return { ...prev, pageNumber: 1, searchArray };
      }
      searchArray[name] = value;
      return { ...prev, pageNumber: 1, searchArray };
    });
  };
  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      initData(newFilter);
    }, 500),
    [],
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const reloadData = async () => {
    return await initData(filter);
  };

  const deleteItem = async (row) => {
    setLoading(true);
    return ApiServices.deleteClient(row.id)
      .then((res) => {
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Xóa thành công');
          reloadData();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Xóa thất bại !!!');
        console.warn('deleteCell - companies', err);
      })
      .finally(() => setLoading(false));
  };

  const onHandleImport = (event) => {
    if (loadingImport || !event) return;

    const file = event?.target?.files[0];
    inputRef.current.value = '';
    if (!file) {
      window.UIMessage?.error('Tải file excel thất bại !!!');
      return;
    }
    setLoadingImport(true);

    return ApiServices.importExcelClient({ excelFile: file })
      .then((res) => {
        if (res?.data) {
          window.UIMessage?.success(
            `Import excel thành công - Thêm mới ${res?.data?.insert} - Cập nhật ${res?.data?.update}`,
            3000,
          );
          // event.target.value = '';
          reloadData();
        }
      })
      .catch((err) => {
        console.warn('import excel - Clients', err);
        window.UIMessage?.error('Import excel thất bại !!!');
      })
      .finally(() => setLoadingImport(false));
  };
  const handleExportClient = () => {
    setLoading(true);
    return ApiServices.exportExcelClient()
      .then((res) => {
        if (res?.data) {
          Utils.exportTemplate(
            res?.data,
            `Danh_Sach_Nguoi_Ho_Tro_${Utils.formatSimpleDate(moment())}.xlsx`,
          );
        }
      })
      .catch((err) => {
        console.log('Export Client file error:', err);
        window.UIMessage?.error('Tải thất bại !!!');
      })
      .finally(() => setLoading(false));
  };
  const handleExportTemplate = () => {
    setLoading(true);
    return ApiServices.exportTemplates(CLIENT_TRASH_TEMPLATE)
      .then((res) => {
        if (res?.data) {
          Utils.exportTemplate(res?.data, `Lead_Moi_bieu_mau.xlsx`);
        }
      })
      .catch((err) => {
        console.log('Export Tempalte error:', err);
        window.UIMessage?.error('Tải thất bại !!!');
      })
      .finally(() => setLoading(false));
  };

  const renderHeader = () => (
    <>
      <ContainerHeader>
        <Typography variant="h6" component="h6" sx={{ m: '10px' }}>
          Danh Sách Người Hỗ Trợ
        </Typography>
      </ContainerHeader>

      <IconButton aria-label="filter" sx={{ color: '#6C757D' }}>
        <BsFilter />
      </IconButton>
      <ButtonImport
        variant="outlined"
        component="label"
        startIcon={<BsBoxArrowInUpRight />}
        disabled={loadingImport}
      >
        Nhập excel
        <input
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          type="file"
          hidden
          ref={inputRef}
          onChange={onHandleImport}
        />
      </ButtonImport>
      <ButtonExport
        variant="outlined"
        startIcon={<BsArrowDownCircle />}
        onClick={handleExportClient}
      >
        Tải excel
      </ButtonExport>
      <ButtonExport
        variant="outlined"
        startIcon={<BsFillStickiesFill />}
        onClick={handleExportTemplate}
      >
        Biểu mẫu
      </ButtonExport>
      <ButtonAdd
        variant="contained"
        startIcon={<BsPlusCircle />}
        onClick={() => setSelected({ isNew: true })}
      >
        Thêm mới
      </ButtonAdd>
    </>
  );

  const validateValue = (row) => {
    const dataSelect = row;
    dataSelect.birthday = Utils.checkIsDate(row.birthday)
      ? Utils.convertToDateFormat(row.birthday)
      : '';
    dataSelect.nationality = row.nationality || '';
    dataSelect.religion = row.religion || '';
    dataSelect.arrAddress = row.arrAddress || [];
    dataSelect.fax = row.fax || '';
    dataSelect.email = row.email || '';
    return dataSelect;
  };
  const handleClickDetail = (row) => {
    const dataSelect = validateValue(row);
    setSelected(dataSelect);
  };
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Table
          filter={filter}
          rows={clients}
          headCells={Columns(handleSearchColumn, deleteItem)}
          totalRecords={totalRecords}
          onPageChange={(paging) => {
            setFilter((prev) => ({ ...prev, ...paging }));
          }}
          onClickDetail={handleClickDetail}
          loading={loading}
          renderHeader={renderHeader}
        />
        <FormDetail {...{ selected, setSelected, reloadData }} />
      </Box>
    </>
  );
}
export { Clients };
