import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, ArrayInput, Selection, SimpleComboBox } from 'components';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';
import { PHONE_REG_EXP } from 'commons/constants';
import ApiServices from 'services';
import { SEX, RESPONSE_STATUSES } from 'commons/constants';
import Utils from 'commons/utils';

const SEX_OPTIONS = [
  { value: 0, content: SEX[0] },
  { value: 1, content: SEX[1] },
];

const FORM_WIDTH = '500px';
export const FormDetail = ({ selected, setSelected, reloadData, ...props }) => {
  const [clientCompanyId, setClientCompanyId] = useState('');

  useEffect(() => {
    if (selected?.clientCompanyId) {
      setClientCompanyId(selected?.clientCompanyId);
    }
  }, [selected]);

  const handleChangeClient = (item) => {
    setClientCompanyId(item);
  };

  const createFn = async (values, { setSubmitting }) => {
    values.clientCompanyId = clientCompanyId;
    if (!values.birthday) {
      delete values.birthday;
    }
    setSubmitting(true);
    return ApiServices.createClient(values)
      .then((res) => {
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Thêm mới thành công');
          setSelected(null);
          reloadData();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Thêm mới thất bại !!!');
        console.log(err);
      })
      .finally(() => setSubmitting(false));
  };

  const updateFn = async (values, { setSubmitting }) => {
    values.clientCompanyId = clientCompanyId;
    if (!values.birthday) {
      delete values.birthday;
    }
    delete values.updatedOn;
    setSubmitting(true);
    return ApiServices.updateClient(values.id, values)
      .then((res) => {
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Cập nhật thành công');
          setSelected(null);
          reloadData();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Cập nhật thất bại !!!');
        console.log(err);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <HandleFormDetail
        {...{ selected, setSelected, width: FORM_WIDTH }}
        showTitleTab={selected?.isNew ? 'Tạo mới' : `Mã: ${selected?.code}`}
      >
        <Formik
          initialValues={{
            code: '',
            fullName: '',
            sex: '',
            nationality: '',
            religion: '',
            birthday: '',
            arrAddress: [],
            phoneNumber: '',
            fax: '',
            email: '',
            ...selected,
          }}
          validationSchema={Yup.object().shape({
            code: Yup.string()
              .max(15, 'Mã chỉ được nhập tối đa 15 ký tự')
              .required('Vui lòng nhập mã KH!!!'),
            fullName: Yup.string().max(255).required('Vui lòng nhập tên người hỗ trợ!!!'),
            email: Yup.string().max(255).email('Email không hợp lệ'),
            sex: Yup.string().max(255).required('Vui lòng nhập giới tính'),
            phoneNumber: Yup.string()
              .required('Vui lòng nhập số điện thoại')
              .matches(PHONE_REG_EXP, 'Số điện thoại không hợp lệ')
              .min(10, 'Số điện thoại có 10 ký tự')
              .max(10, 'Số điện thoại có 10 ký tự'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            let phoneError = [];
            if (values?.arrNumberPhone?.length) {
              values?.arrNumberPhone?.forEach((phone) => {
                const checkIsNumberPhone = Utils.isNumber(phone);
                if (!checkIsNumberPhone) {
                  phoneError.push(phone);
                  return;
                }
                const checkIsLength = phone?.length <= 14 && phone?.length > 1;
                if (!checkIsLength) {
                  phoneError.push(phone);
                  return;
                }
              });
            }
            if (phoneError?.length > 0) {
              window.UIMessage?.error(
                'Số điện thoại không hợp lệ',
                `Các số điện thoại không hợp lệ: ${phoneError.join(', ')}`,
              );
              setSubmitting(false);
              return;
            }
            if (values?.email) {
              const checkEmail = Utils.isEmail(values?.email);
              if (!checkEmail) {
                window.UIMessage?.error('Email không hợp lệ');
                setSubmitting(false);
                return;
              }
            }

            if (selected?.isNew) {
              createFn(values, { setSubmitting });
            } else {
              updateFn(values, { setSubmitting });
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <TextInput
                required
                error={Boolean(touched.code && errors.code)}
                helperText={touched.code && errors.code}
                label="Mã"
                name="code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
              />
              <TextInput
                required
                error={Boolean(touched.fullName && errors.fullName)}
                helperText={touched.fullName && errors.fullName}
                label="Tên người hỗ trợ"
                name="fullName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fullName}
              />
              <SimpleComboBox
                label="Khách hàng (*)"
                disableClearable
                sx={{ marginY: '5px' }}
                value={clientCompanyId}
                keyValue="id"
                keyFieldView="name"
                renderLabel={(option) => `${option?.code} - ${option?.name}`}
                renderOption={(option) => (
                  <>
                    <span style={{ color: 'red' }}>{option?.code} </span> - {option?.name}
                  </>
                )}
                onChange={handleChangeClient}
                syncData={ApiServices.getClientCompanies}
              />
              <Selection
                error={Boolean(touched.sex && errors.sex)}
                helperText={touched.sex && errors.sex}
                label="Giới tính"
                name="sex"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.sex}
                dataArrObject={SEX_OPTIONS}
              />
              <TextInput
                error={Boolean(touched.nationality && errors.nationality)}
                helperText={touched.nationality && errors.nationality}
                label="Quốc tịch"
                name="nationality"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nationality}
              />
              <TextInput
                error={Boolean(touched.religion && errors.religion)}
                helperText={touched.religion && errors.religion}
                label="Tôn giáo"
                name="religion"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.religion}
              />
              <TextInput
                error={Boolean(touched.birthday && errors.birthday)}
                helperText={touched.birthday && errors.birthday}
                label="Năm sinh"
                name="birthday"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.birthday}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <ArrayInput
                value={values.arrAddress}
                fullWidth
                onChange={handleChange}
                label="Địa chỉ"
                name="arrAddress"
              />
              <TextInput
                required
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                helperText={touched.phoneNumber && errors.phoneNumber}
                label="Số điện thoại"
                name="phoneNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phoneNumber}
              />
              <TextInput
                error={Boolean(touched.fax && errors.fax)}
                helperText={touched.fax && errors.fax}
                label="Fax"
                name="fax"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fax}
              />
              <TextInput
                required
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                label="Email"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting || !clientCompanyId}
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </HandleFormDetail>
    </>
  );
};
