import React, { useState } from 'react';
import { Box, Paper, Typography, Button, Toolbar } from '@mui/material';
import { BsClockHistory } from 'react-icons/bs';
import { styled } from '@mui/material/styles';
import { SearchInput } from 'components';
import { ClientCarelist } from './client-care-list';
import { DrawerLeadWaitCare } from './drawer-lead-wait-care';
import ApiServices from 'services';
import { CLIENT_STATUS_ID } from 'commons/constants';

const ClientCareHistory = () => {
  const { LEAD_TAKING_CARE, DEAL, DEAL_SUCCESS } = CLIENT_STATUS_ID;
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [columns, setColumns] = useState({});

  const getDataInit = async () => {
    let [getLeadsTakingCare, getDeals, getDealsSuccessful] = await Promise.all([
      ApiServices.getClientCareHistoryByStatus(LEAD_TAKING_CARE),
      ApiServices.getClientCareHistoryByStatus(DEAL),
      ApiServices.getClientCareHistoryByStatus(DEAL_SUCCESS),
    ]);

    setColumns({
      [LEAD_TAKING_CARE]: {
        name: 'Lead đang chăm sóc',
        items: getLeadsTakingCare.data || [],
      },
      [DEAL]: {
        name: 'Deal',
        items: getDeals.data || [],
      },
      [DEAL_SUCCESS]: {
        name: 'Deal thành công',
        items: getDealsSuccessful.data || [],
      },
    });
  };

  React.useEffect(() => {
    getDataInit();
  }, []);

  return (
    <>
      <Container>
        <Header>
          <Typography variant="h6" id="tableTitle" component="div">
            QUẢN LÝ LEAD
          </Typography>
          <ContainerRight>
            <SearchInput sx={{ mr: 2 }} />
            <LeadWaitCare
              variant="contained"
              size="small"
              startIcon={<BsClockHistory />}
              onClick={() => setIsOpenDrawer(true)}
              onMouseOver={() => console.log('Hello')}
            >
              Chờ chăm sóc
            </LeadWaitCare>
          </ContainerRight>
        </Header>

        <ClientCarelist columns={columns} setColumns={setColumns} getDataInit={getDataInit} />
      </Container>

      <DrawerLeadWaitCare
        isOpen={isOpenDrawer}
        setIsOpen={setIsOpenDrawer}
        getDataInit={getDataInit}
      />
    </>
  );
};

export { ClientCareHistory };

const Container = styled(Paper)(({ theme }) => ({
  width: '100%',
  mb: 1,
  position: 'relative',
  overflow: 'hidden',
}));

const Header = styled(Toolbar)(({ theme }) => ({
  pl: { sm: 2 },
  pr: { xs: 1, sm: 1 },
}));

const ContainerRight = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  mr: 2,
}));

const LeadWaitCare = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  marginLeft: '8px',
  color: '#FFFFFF',
  backgroundColor: '#F9A907',
  '.MuiButton-endIcon': {
    marginLeft: '0px',
  },
  padding: '0px 16px',
}));
