import * as React from 'react';
import { Box, Divider, Typography, Grid } from '@mui/material';
import Utils from 'commons/utils';
import ApiServices from 'services';

const TabCallManagement = ({ clientCareHistoryItem }) => {
  const [calls, setCalls] = React.useState([]);

  const getCallByClientCareHistory = async () => {
    try {
      const { data } = await ApiServices.getCallByClientCareHistory(clientCareHistoryItem.id);
      setCalls(data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getCallByClientCareHistory();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100px',
        backgroundColor: 'rgb(234, 238, 243)',
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        borderRadius: '15px',
        fontSize: '12px',
      }}
    >
      <Typography mt={2} align="center" component="div" variant="h6" sx={{ marginTop: '0px' }}>
        Lịch sử cuộc gọi
      </Typography>
      <Divider />
      {/* header */}
      <Grid container>
        <Grid item xs={2} sx={{ padding: 1 }}>
          <Typography m={1} align="center" component="b" variant="string">
            TG bắt đầu
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ padding: 1 }}>
          <Typography m={1} align="center" component="b" variant="string">
            TG kết thúc
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <Typography m={1} align="center" component="b" variant="string">
            Nội dung
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <Typography m={1} align="center" component="b" variant="string">
            File ghi âm
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ padding: 1 }}>
          <Typography m={1} align="center" component="b" variant="string">
            Người hỗ trợ
          </Typography>
        </Grid>
      </Grid>
      {/* body - mapdata */}
      {calls.map((item) => (
        <div key={item.id}>
          <Divider variant="middle" />
          <Grid container>
            <Grid item xs={2} sx={{ padding: 1 }}>
              {Utils.formatDateHourFn(item?.callTimeFrom)}
            </Grid>
            <Grid item xs={2} sx={{ padding: 1 }}>
              {Utils.formatDateHourFn(item?.callTimeTo)}
            </Grid>
            <Grid item xs={3} sx={{ padding: 1 }}>
              {item?.note}
            </Grid>
            <Grid item xs={3} sx={{ padding: 1 }}>
              {item?.urlFile}
            </Grid>
            <Grid item xs={2} sx={{ padding: 1 }}>
              {item?.clientName}
            </Grid>
          </Grid>
        </div>
      ))}
    </Box>
  );
};

export { TabCallManagement };
