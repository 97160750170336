import * as React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { TextInput, DateTimePicker } from 'components';
import { Box, Button } from '@mui/material';
import ApiServices from 'services';
import Storage from 'storage';

const ScheduleForm = ({
  setOpenScheduleForm,
  clientCareHistoryItem,
  setValue,
  idClientSupport,
}) => {
  const addScheduleFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    values.clientCareHistoryId = clientCareHistoryItem.id;
    values.scheduler = Storage?.user?.Code;
    values.meetingClientId = idClientSupport;
    try {
      const { status } = await ApiServices.createScheduleManagement(values);
      if (status === 200) {
        window.UIMessage?.success('Thêm lịch hẹn thành công');
        setOpenScheduleForm(false);
        return setValue('4');
      } else {
        window.UIMessage?.error('Thêm lịch hẹn thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  return (
    <>
      <Formik
        initialValues={{
          appointmentName: '',
          time: '',
          location: '',
          note: '',
        }}
        validationSchema={Yup.object().shape({
          appointmentName: Yup.string().max(255).required('Vui lòng nhập tên cuộc hẹn !!!'),
          time: Yup.string().max(255).required('Vui lòng chọn thời gian hẹn !!!'),
          location: Yup.string().max(255).required('Vui lòng nhập địa điểm hẹn !!!'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          addScheduleFn(values, { setSubmitting });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit} style={{ width: '500px' }}>
            <TextInput
              error={Boolean(touched.appointmentName && errors.appointmentName)}
              helperText={touched.appointmentName && errors.appointmentName}
              label="Cuộc hẹn"
              name="appointmentName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.appointmentName}
            />
            <DateTimePicker
              onChange={handleChange}
              value={values.time}
              error={Boolean(touched.time && errors.time)}
              helperText={touched.time && errors.time}
              label="Thời gian hẹn"
              name="time"
            />
            <TextInput
              error={Boolean(touched.location && errors.location)}
              helperText={touched.location && errors.location}
              label="Địa điểm"
              name="location"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.location}
            />
            <TextInput
              error={Boolean(touched.note && errors.note)}
              helperText={touched.note && errors.note}
              label="Nội dung"
              name="note"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.note}
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="small"
                type="submit"
                variant="contained"
              >
                Hẹn Gặp
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export { ScheduleForm };
