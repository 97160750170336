import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Table } from 'components';
import moment from 'moment';
import { FormDetail } from './form-detail';
import { Box, IconButton, Typography } from '@mui/material';
import {
  BsBoxArrowInUpRight,
  BsArrowDownCircle,
  BsPlusCircle,
  BsFilter,
  BsFillStickiesFill,
} from 'react-icons/bs';
import Utils from 'commons/utils';
import ApiServices from 'services';
import ServicesList from './services-list';
import { getColumns } from './columns';
import { ContainerHeader, ButtonAdd, ButtonImport, ButtonExport } from './styles';
import { debounce } from 'lodash';

const SERVICES_WIDTH = '200px';
const CLIENT_TRASH_TEMPLATE = 'Client_Company_Template';
const DEFAULT_FILTER = {
  pageNumber: 1,
  pageSize: 10,
};

export function NewLead() {
  const inputRef = useRef();
  const [data, setData] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const [selected, setSelected] = useState(null);
  const [services, setServices] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [filter, setFilter] = useState(DEFAULT_FILTER);

  const getServices = () => {
    setLoading(true);
    return ApiServices.getServices()
      .then((res) => {
        if (res?.data) {
          const serviceId = res?.data?.map((item) => item?.id);
          setServiceList(serviceId || []);
        }
      })
      .catch((err) => console.warn('get Service:', err))
      .finally(() => setLoading(false));
  };

  const initData = (query) => {
    setLoading(true);
    return ApiServices.getNewLead(query ? query : filter)
      .then((res) => {
        if (res?.data) {
          if (totalRecords !== res?.data?.totalRecords) setTotalRecords(res?.data?.totalRecords);
          setData(res?.data?.data || []);
        }
      })
      .catch((err) => console.warn('get New Lead:', err))
      .finally(() => setLoading(false));
  };

  const handleSearchColumn = (event) => {
    setFilter((prev) => {
      const searchArray = { ...prev.searchArray };
      const { value, name } = event.target;
      if (!value) {
        delete searchArray[name];
        return { ...prev, pageNumber: 1, searchArray };
      }
      searchArray[name] = value;
      return { ...prev, pageNumber: 1, searchArray };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      initData(newFilter);
    }, 500),
    [],
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  useEffect(() => {
    getServices();
  }, []);

  const deleteItem = (row) => {
    setLoading(true);
    return ApiServices.cancelNewLead(row.id)
      .then((res) => {
        if (res) {
          window.UIMessage?.success('Hủy thành công');
          initData();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Hủy thất bại !!!');
        console.warn('deleteItem - New Lead', err);
      })
      .finally(() => setLoading(false));
  };

  const onHandleImport = (event) => {
    if (loadingImport || !event) return;

    const file = event?.target?.files[0];
    inputRef.current.value = '';
    if (!file) {
      window.UIMessage?.error('Tải file excel thất bại !!!');
      return;
    }
    setLoadingImport(true);
    return ApiServices.importExcelClientCompany({
      excelFile: file,
    })
      .then((res) => {
        if (res?.data) {
          window.UIMessage?.success(
            `Import excel thành công - Thêm mới ${res?.data?.insert} - Cập nhật ${res?.data?.update}`,
            4000,
          );
          event.target.value = '';
          initData();
        }
      })
      .catch((err) => {
        console.warn('import excel - Lead mới', err);
        window.UIMessage?.error('Import excel thất bại !!!');
      })
      .finally(() => setLoadingImport(false));
  };
  const handleExportClient = () => {
    setLoading(true);
    return ApiServices.exportExcelClientCompany()
      .then((res) => {
        if (res?.data) {
          Utils.exportTemplate(
            res?.data,
            `Danh_Sach_Lead_Moi_${Utils.formatSimpleDate(moment())}.xlsx`,
          );
        }
      })
      .catch((err) => {
        console.log('Export Client file error:', err);
        window.UIMessage?.error('Tải thất bại !!!');
      })
      .finally(() => setLoading(false));
  };

  const handleExportTemplate = () => {
    setLoading(true);
    return ApiServices.exportTemplates(CLIENT_TRASH_TEMPLATE)
      .then((res) => {
        if (res?.data) {
          Utils.exportTemplate(res?.data, `Lead_Moi_bieu_mau.xlsx`);
        }
      })
      .catch((err) => {
        console.log('Export Tempalte error:', err);
        window.UIMessage?.error('Tải thất bại !!!');
      })
      .finally(() => setLoading(false));
  };

  const hasServices = React.useMemo(() => {
    for (let el of Object.values(services)) {
      if (el?.services?.length) {
        return true;
      }
    }
    return false;
  }, [services]);

  const validateValue = (row) => {
    const dataSelect = row;
    dataSelect.foundingDate = Utils.checkIsDate(row.foundingDate)
      ? Utils.convertToDateFormat(row.foundingDate)
      : '';
    dataSelect.birthdayRepresenter = Utils.checkIsDate(row.birthdayRepresenter)
      ? Utils.convertToDateFormat(row.birthdayRepresenter)
      : '';
    dataSelect.tax_Code = row?.tax_Code || '';
    dataSelect.arrAddress = row?.arrAddress || [];
    dataSelect.arrFax = row?.arrFax || [];
    dataSelect.companyType = row?.companyType || '';
    dataSelect.loyaltyTypeId = row?.loyaltyTypeId || '';
    dataSelect.note = row?.note || '';
    dataSelect.cargotype = row?.cargotype || '';
    dataSelect.representer = row?.representer || '';
    dataSelect.emailRepresenter = row?.emailRepresenter || '';
    dataSelect.phoneRepresenter = row?.phoneRepresenter || '';
    dataSelect.email = row?.email || '';
    dataSelect.arrClientCareHistory = row?.arrClientCareHistory || [];
    dataSelect.arrClientEvaluationCriteria = row?.arrClientEvaluationCriteria || [];
    dataSelect.arrNumberPhone = row?.arrNumberPhone || '';
    dataSelect.arrService = row?.arrService || '';
    dataSelect.arrServiceId = row?.arrServiceId || '';

    return dataSelect;
  };

  const handleClickDetail = (row) => {
    const dataSelect = validateValue(row);
    setSelected(dataSelect);
  };

  const renderHeader = () => (
    <>
      <ContainerHeader>
        <Typography variant="h6" component="h6" sx={{ m: '10px' }}>
          Danh Sách Lead Mới
        </Typography>
      </ContainerHeader>

      <IconButton aria-label="filter" sx={{ color: '#6C757D' }}>
        <BsFilter />
      </IconButton>
      <ButtonImport
        variant="outlined"
        component="label"
        startIcon={<BsBoxArrowInUpRight />}
        disabled={loadingImport}
      >
        Nhập excel
        <input
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          type="file"
          hidden
          ref={inputRef}
          onChange={onHandleImport}
        />
      </ButtonImport>
      <ButtonExport
        variant="outlined"
        startIcon={<BsArrowDownCircle />}
        onClick={handleExportClient}
      >
        Tải excel
      </ButtonExport>
      <ButtonExport
        variant="outlined"
        startIcon={<BsFillStickiesFill />}
        onClick={handleExportTemplate}
      >
        Biểu mẫu
      </ButtonExport>
      <ButtonAdd
        variant="contained"
        startIcon={<BsPlusCircle />}
        onClick={() => setSelected({ isNew: true })}
      >
        Thêm mới
      </ButtonAdd>
    </>
  );

  const widthForm = hasServices ? `calc( 100% - ${SERVICES_WIDTH})` : '100%';

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex' }}>
        <Box sx={{ width: widthForm }}>
          <Table
            filter={filter}
            rows={data}
            headCells={getColumns(
              deleteItem,
              services,
              setServices,
              handleSearchColumn,
              serviceList,
            )}
            totalRecords={totalRecords}
            onPageChange={(paging) => {
              setFilter((prev) => ({ ...prev, ...paging }));
            }}
            onClickDetail={handleClickDetail}
            loading={loading}
            renderHeader={renderHeader}
          />
        </Box>
        {hasServices && (
          <ServicesList
            width={SERVICES_WIDTH}
            data={services}
            setData={setServices}
            reload={initData}
          />
        )}
        <FormDetail {...{ selected, setSelected, initData }} />
      </Box>
    </>
  );
}
